import {cleanHtmlForLabels} from "../../app/util/htmlHelpers";

define("framework/globalUtils/labelUtilities", [
  "framework/globalUtils/koNodePreprocessor",
  "shared/multi-labels-modal.htm",
  "shared/labelsModal.htm",
  "app/util/htmlHelpers"
], function(widgets, multiLabelsModal, labelsModal, { cleanHtmlForLabels }) {
  //*********************************************Custom Tags***********************************************************

  var rootElement =
    "<select multiple data-bind=\"options: #{labels}, optionsText: 'name', optionsValue: 'id', selectedOptions: #{labelsCollection}, select2Labels: { #{singleSelect} placeholder: '#{placeholder}', labelsSrc: #{labels}, koDependencies: [#{labelsCollection}], permissionId: #{permissionId} }\"></select>";

  widgets.addElementHandler({
    type: "cr-bulk-labels-add",
    process: processBulkLabelsAdd
  });

  widgets.addElementHandler({
    type: "cr-bulk-labels-remove",
    process: processBulkLabelsRemove
  });

  function processBulkLabelsAdd($node) {
    return executeBulkLabels(
      $node.data("path"),
      $node.data("addToCollection") || "labelsAddingCollection",
      "Click to add tags",
      $node.data("permissionid"),
      $node.data("sourceOverride"),
      $node.data("singleSelect")
    );
  }

  function processBulkLabelsRemove($node) {
    return executeBulkLabels(
      $node.data("path"),
      "labelsRemovingCollection",
      "Click to remove tags",
      $node.data("permissionid"),
      $node.data("sourceOverride")
    );
  }

  function executeBulkLabels(path, collection, placeholder, permissionId, sourceOverride, singleSelectMessage) {
    path = path ? path + "." : "";
    return rootElement
      .replace(/#{labels}/g, path + (sourceOverride || "allLabels"))
      .replace(/#{labelsCollection}/g, path + collection)
      .replace(/#{placeholder}/g, placeholder)
      .replace(/#{permissionId}/g, permissionId)
      .replace(/#{singleSelect}/g, singleSelectMessage ? "maximumSelectionSize: 1, formatSelectionTooBig: '" + singleSelectMessage + "', " : "");
  }

  widgets.addElementHandler({
    type: "cr-immediatelabels",
    process: function($node) {
      var rootName = $node.data("rootName"),
        allLabels = $node.data("allLabels"),
        permissionId = $node.data("permissionid"),
        placeholderPersist = $node.data("placeholder-persist"),
        baseTag =
          "<select style='min-width: 450px;' multiple data-bind=\"selectedOptions: #{root}CurrentlySelectedIds, options: #{all}, optionsText: 'name', optionsValue: 'id', valueAllowUnset: true, select2Labels: { placeholder: 'Click here to add labels...', onChange: #{root}AddedOrRemoved, labelsSrc: #{all}, koDependencies: [#{all}, #{root}DependencyCoercer], permissionId: #{permissionId}, placeholderPersist: #{placeholderPersist} }\"></select>";

      return baseTag
        .replace(/#{root}/g, rootName)
        .replace(/#{all}/g, allLabels)
        .replace(/#{permissionId}/g, permissionId)
        .replace(/#{placeholderPersist}/g, placeholderPersist);
    }
  });

  widgets.addElementHandler({
    type: "cr-multi-labels-modal",
    process: function($node) {
      var $dest = widgets.mapAttributes($node, multiLabelsModal, ["data-header"]),
        headerOverrideText = $node.data("header"),
        bodyDisplay = $node.find("body-item-display").remove(),
        headerDisplay = $node.find("header-display").remove(),
        permissionId = $node.data("permissionid");
      $node.removeAttr("data-permissionid");

      if (headerOverrideText) $dest.find(".modal-header h4").text(headerOverrideText);
      else if (headerDisplay.length) $dest.find(".modal-header h4").replaceWith(headerDisplay.html());

      if (bodyDisplay.length) $dest.find(".body-header").append(bodyDisplay.html());

      var addNode = $dest.find("cr-bulk-labels-add").attr("data-permissionid", permissionId);
      addNode.replaceWith(processBulkLabelsAdd(addNode));

      var removeNode = $dest.find("cr-bulk-labels-remove").attr("data-permissionid", permissionId);
      addNode.replaceWith(processBulkLabelsRemove(removeNode));

      return $dest;
    }
  });

  widgets.addElementHandler({
    type: "labels-modal",
    process: function() {
      return labelsModal;
    }
  });

  //*****************************************KO Bindings***********************************************************

  ko.bindingHandlers.select2Labels = {
    init: function(element, valueAccessor, ab, viewModel) {
      var vaUnrolled = valueAccessor(),
        permissionId = +vaUnrolled.permissionId,
        canAddOrgLabels = !!permissionId && cr.getUser().hasPermission(permissionId);

      if (vaUnrolled.textProperty) {
        vaUnrolled.formatResult = function(o) {
          return o[vaUnrolled.textProperty];
        };
        vaUnrolled.formatSelection = function(o) {
          return o[vaUnrolled.textProperty];
        };
      } else if (vaUnrolled.labelsSrc) {
        //either org labels don't exist, in which case we shouldn't show org/private icons, since they'll all be private; or I'm the org, and I shouldn't
        //show org/private icons since they'll all be org: either way, don't show these icons.
        if (isNaN(permissionId) || cr.getUser().isCurrentlyTheOrganization()) {
          vaUnrolled.formatSelection = function(o, c) {
            var l = ko.toJS(
              ko.utils.arrayFirst(vaUnrolled.labelsSrc(), function(l) {
                return l.id() == o.id;
              })
            );
            if (l) c.parent().css({ "border-bottom": "solid 4px #" + l.backgroundColor });
            return o.text;
          };

          vaUnrolled.formatResult = function(o) {
            var label = ko.utils.arrayFirst(vaUnrolled.labelsSrc(), function(l) {
                return l.id() == o.id;
              }),
              levels = vaUnrolled.labelsSrc.getHierarchyLevel(label),
              levelsTags = "";

            for (var i = 0; i < levels; i++) {
              levelsTags += '<i class="fa fa-angle-right fa-fw"></i>';
            }

            return levelsTags + '<span class="swatch" style="background:#' + label.backgroundColor() + '"></span>' + o.text;
          };
        } else {
          //else, there will be both private and org labels, so make sure we show icons, and check permissions.
          vaUnrolled.formatSelection = function(o, c) {
            var l = ko.toJS(
                ko.utils.arrayFirst(vaUnrolled.labelsSrc(), function(l) {
                  return l.id() == o.id;
                })
              ),
              iTag = "";
            if (l) {
              c.parent().css({ "border-bottom": "solid 4px #" + l.backgroundColor });
              if (!canAddOrgLabels && l.isPublic) {
                c.parent()
                  .find("a.select2-search-choice-close")
                  .hide();
              }
              iTag = '<i class="fa fa-fw fa-user' + (l.isPublic ? "s" : "") + '"></i> ';
            }
            return iTag + o.text;
          };

          vaUnrolled.formatResult = function(o) {
            var label = ko.utils.arrayFirst(vaUnrolled.labelsSrc(), function(l) {
                return l.id() == o.id;
              }),
              labelUnrolled = ko.toJS(label),
              levels = vaUnrolled.labelsSrc.getHierarchyLevel(label),
              levelsTags = "",
              iTag = "";

            for (var i = 0; i < levels; i++) {
              levelsTags += '<i class="fa fa-angle-right fa-fw"></i>';
            }

            if (labelUnrolled) {
              if (!canAddOrgLabels && labelUnrolled.isPublic) return null;
              iTag = '<i class="fa fa-fw fa-user' + (labelUnrolled.isPublic ? "s" : "") + '"></i> ';
            }
            return levelsTags + iTag + '<span class="swatch" style="background:#' + label.backgroundColor() + '"></span>' + o.text;
          };
        }

        vaUnrolled.matcher = function(term, text, option) {
          if (text.toUpperCase().indexOf(term.toUpperCase()) >= 0) return true;
          if (!option.context.value) return false;

          var label = ko.utils.arrayFirst(vaUnrolled.labelsSrc(), function(l) {
              return l.id() == option.context.value;
            }),
            allChildren = cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.flattenLabelCollection([label]);

          if (
            Linq.From(allChildren).Any(function(l) {
              return (
                l
                  .name()
                  .toUpperCase()
                  .indexOf(term.toUpperCase()) >= 0
              );
            })
          ) {
            return true;
          }

          return false;
        };
      }

      $(element).select2($.extend({ showSelectedOptions: true }, vaUnrolled, {}));

      if (vaUnrolled.onChange) {
        $(element).on("change", function(e, k) {
          vaUnrolled.onChange(e);
        });
      }

      ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
        var $element = $(element),
          select2 = $element.data("select2");

        if (select2) {
          select2.opts.element.show = $.noop;
        }
        $element.select2("destroy");
        element = null;
        $element = null;
      });
    },

    update: function(element, valueAccessor, allBindingsAccessor, viewModel) {
      var vaUnrolled = valueAccessor(),
        koDependencies = vaUnrolled.koDependencies;

      var $element = $(element);

      $element.trigger("change");

      if ($.isArray(koDependencies)) {
        koDependencies.forEach(function(koDep) {
          koDep();
        });
      }

      $element &&
        vaUnrolled.placeholderPersist &&
        vaUnrolled.placeholder &&
        $element
          .parent()
          .find("input:text")
          .attr("placeholder", vaUnrolled.placeholder);
    }
  };

  ko.bindingHandlers.addColorPickerForLabelsModal = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel) {
      var colorSync = allBindingsAccessor().labelsColorModalOptions.syncColorsTo;
      var textSync = allBindingsAccessor().labelsColorModalOptions.syncPreviewTextTo;

      viewModel.colorPicker = new CRColorPicker();

      colorSync.subscribe(function(val) {
        if (!val) return;
        viewModel.colorPicker.setColors(val.backgroundColor, val.color);
      });

      textSync.subscribe(function(val) {
        $(element)
          .find(".pickerPreview")
          .text(val || "<preview text here>");
      });
    }
  };

  //*******************************************VM Mapper Plugins******************************************************

  //DEPRECATED: use globalUtils/singleLabelsSelect2Component
  function singleLabelsCollectionSelect2Plugin(options) {
    var labelsRootName = options.labelCollection || "labels";

    return {
      postInitialize: function() {
        const getAllLabels = () => {
          if (options.allLabels) {
            return options.allLabels();
          } else {
            return this.allLabels();
          }
        };

        var labels = this[labelsRootName];

        this[labelsRootName + "CurrentlySelectedData"] = ko.computed(function() {
          var allLabelIds = getAllLabels().map(function(l) {
            return +l.id();
          });
          return labels()
            .filter(function(l) {
              return allLabelIds.indexOf(+l.id()) > -1;
            })
            .map(function(l) {
              return { id: +l.id(), text: l.name() };
            });
        }, this);

        this[labelsRootName + "CurrentlySelectedIds"] = ko.computed(function() {
          var allLabelIds = getAllLabels().map(function(l) {
            return +l.id();
          });
          return labels()
            .filter(function(l) {
              return allLabelIds.indexOf(+l.id()) > -1;
            })
            .map(function(l) {
              return +l.id();
            });
        }, this);

        this[labelsRootName + "DependencyCoercer"] = ko
          .computed(function() {
            var dependentProperties = ["name", "color", "backgroundColor"];
            labels().forEach(function(l) {
              dependentProperties.forEach(function(prop) {
                l[prop]();
              });
            });
          }, this)
          .extend({ notify: "always" });

        this[labelsRootName + "AddedOrRemoved"] = function(obj) {
          if (obj.added)
            labels.push(cr.viewModelFactory.commonViewModels.individualLabelVm.createFromResponse($.extend(obj.added, { name: obj.added.text })));
          if (obj.removed)
            labels.remove(function(l) {
              return l.id() == obj.removed.id;
            });

          if (obj.added || obj.removed) {
            var req = { ids: ko.unwrap(this.id), add: obj.added ? obj.added.id : "", remove: obj.removed ? obj.removed.id : "" };
            return cr.transport.transmitRequest(options.transportId, options.saveLabelsChange, req, function() {
              cr.router.currentModuleBusConnection.sendMessage("single-labels-select2-plugin.labels-added-or-removed", req);
            });
          }
        }.bind(this);

        var singularName = labelsRootName.charAt(0).toUpperCase() + labelsRootName.slice(1, labelsRootName.length - 1);
        this["get" + singularName + "ById"] = function(id) {
          return Linq.From(labels()).SingleOrDefault(null, function(label) {
            return label.id() == id;
          });
        };
      },
      define: function(config, pubs, F) {
        config.mappedArrays.push({ name: labelsRootName, viewModelType: cr.viewModelFactory.commonViewModels.individualLabelVm });

        //for when nestedLabels saves a label
        pubs.applySavedLabel = function(mapLabelObject) {
          this[labelsRootName]().forEach(function(l) {
            if (l.id() == mapLabelObject.Id) {
              l.mapFromResponse(mapLabelObject);
            }
          });
        };

        pubs.reconcileLabelsAfterMassUpdate = function(updatePacket) {
          const getAllLabels = () => {
            if (options.allLabels) {
              return options.allLabels();
            } else {
              return this.allLabels();
            }
          };

          if (typeof updatePacket.ids === "number") {
            updatePacket.ids = "" + updatePacket.ids;
          }
          if (updatePacket.ids.split(",").indexOf("" + ko.unwrap(this.id)) < 0) return;

          updatePacket.add.split(",").forEach(
            function(id) {
              var labelToAdd;
              if (!this.getLabelById(id) && (labelToAdd = getAllLabels().find(l => l.id() == id))) {
                this.labels.push(cr.viewModelFactory.commonViewModels.individualLabelVm.createFromResponse(ko.toJS(labelToAdd)));
              }
            }.bind(this)
          );

          updatePacket.remove.split(",").forEach(
            function(id) {
              var labelToRemove;
              if ((labelToRemove = this.getLabelById(id))) {
                this.labels.remove(labelToRemove);
              }
            }.bind(this)
          );
        };

        //for when nestedLabels deletes a label
        pubs.applyDeletedLabel = function(deletedLabelToken) {
          this[labelsRootName].remove(function(l) {
            return l.id() == deletedLabelToken.labelId;
          });
        };

        pubs.disposeSingleLabels = function() {
          this[labelsRootName + "CurrentlySelectedData"].dispose();
          this[labelsRootName + "CurrentlySelectedIds"].dispose();
          this[labelsRootName + "DependencyCoercer"].dispose();
        };
      }
    };
  }

  function addGenericLabelsCollection(localConfig = {}) {
    var extraLabelsCollections = localConfig.otherLabelsCollections || [];

    return {
      initialize: function() {},
      define: function(config, pubs, F) {
        config.mappedArrays.push({ name: "labels", viewModelType: cr.viewModelFactory.commonViewModels.individualLabelVm });

        if (localConfig.labelsComponent) {
          Object.defineProperty(pubs, "currentLabels", {
            get() {
              return this.labels()
                .map(({ id }) => localConfig.labelsComponent.getLabelById(ko.unwrap(id)))
                .filter(l => l);
            }
          });
        }

        for (var i = 0; i < extraLabelsCollections.length; i++) {
          config.mappedArrays.push({ name: extraLabelsCollections[i], viewModelType: cr.viewModelFactory.commonViewModels.individualLabelVm });

          //TODO: add selectedIds for these
        }

        pubs.addTheseLabels = function(labels) {
          var existingLabelIds = Linq.From(this.labels()).Select(function(l) {
            return +l.id();
          });

          labels
            .filter(function(l) {
              return !existingLabelIds.Contains(+l.id);
            })
            .forEach(
              function(label) {
                label.backgroundColor = (label.backgroundColor || "dddddd").replace("#", "");
                label.color = (label.color || "000000").replace("#", "");
                this.labels.push(cr.viewModelFactory.commonViewModels.individualLabelVm.createFromResponse(label));
              }.bind(this)
            );
        };

        pubs.removeTheseLabels = function(ids) {
          var queryAbleIds = ids.map(function(id) {
            return +id;
          });

          this.labels()
            .filter(function(l) {
              return queryAbleIds.indexOf(+l.id()) > -1;
            })
            .forEach(
              function(label) {
                this.labels.remove(label);
              }.bind(this)
            );
        };

        pubs.getLabelById = function(id) {
          return Linq.From(this.labels()).SingleOrDefault(null, function(label) {
            return label.id() == id;
          });
        };
      }
    };
  }

  //****************************************Other utilities**********************************************************

  var CRHierarchicalLabelModal = (function() {
    var newLabelPrompt = { name: "", id: ko.observable(null) },
      newLabelOption = {
        name: ko.observable(""),
        color: ko.observable("ffffff"),
        backgroundColor: ko.observable("999999"),
        id: ko.observable(0),
        parentLabelId: ko.observable("")
      };

    function CRHierarchicalLabelModal(labels, messageBus, isOrgLabels) {
      //this really sucks, but the translation stuff isn't available up front
      newLabelPrompt.name = "Select a label to edit";
      newLabelOption.name("Add new label");

      //this.colorPicker set up in addColorPickerForLabelsModal binding
      this.labels = labels;
      this.messageBus = messageBus;
      this.isPublic = !!isOrgLabels;
      this.successMessage = ko.observable("");

      this.deleteConfirm = ko.observable(false);

      this.messageBus.subscribe(
        "*",
        "labelSaved",
        function() {
          this.setSuccessMessage("Label saved");
          this.currentLabel(newLabelPrompt);
        }.bind(this)
      );

      this.messageBus.subscribe(
        "*",
        "labelDeleted",
        function() {
          this.setSuccessMessage("Label deleted");
        }.bind(this)
      );

      this.labelDataForModal = ko.computed(function() {
        return [newLabelPrompt, newLabelOption].concat(this.labels());
      }, this);

      this.currentLabelParentValue = ko.observable(null);
      this.currentLabel = ko.observable(newLabelPrompt);

      this.candidateParents = ko.computed(function() {
        if (this.currentLabel() === newLabelPrompt) return [];
        return this.labels().filter(
          function(l) {
            return l.id() != this.currentLabel().id();
          }.bind(this)
        );
      }, this);

      this.currentLabel.subscribe(function(val) {
        this.currentLabelParentValue(val === newLabelPrompt || val === newLabelOption || !val.parentLabelId() ? null : val.parentLabelId());
        this.currentLabelColors(val === newLabelPrompt ? null : { color: val.color(), backgroundColor: val.backgroundColor() });
        this.currentLabelText(val === newLabelPrompt ? "" : val.name());
        this.deleteConfirm(false);
      }, this);

      this.errors = ko.observableArray([]);

      this.currentLabelColors = ko.observable(null);
      this.currentLabelText = ko.observable("");

      this.workingWithLabel = ko.computed(function() {
        return this.currentLabel() !== newLabelPrompt;
      }, this);
      this.canDeleteLabel = ko.computed(function() {
        return this.currentLabel() !== newLabelOption;
      }, this);
    }

    function isThisLabelDescendantOfThat(thisLabel, thatLabel) {
      var flattenedDescendants = cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.flattenLabelCollection([thatLabel]);
      return flattenedDescendants.some(function(label) {
        return label === thisLabel;
      });
    }

    CRHierarchicalLabelModal.prototype.setSuccessMessage = function(val) {
      this.successMessage(val);
      setTimeout(
        function() {
          this.successMessage("");
        }.bind(this),
        3000
      );
    };

    CRHierarchicalLabelModal.prototype.refreshNonBoundItems = function() {
      this.currentLabelText.valueHasMutated();
      this.currentLabelColors.valueHasMutated();
    };

    CRHierarchicalLabelModal.prototype.open = function() {
      this.currentLabel(newLabelPrompt);
      this.errors([]);
    };

    CRHierarchicalLabelModal.prototype.updateColors = function() {
      var colors = this.colorPicker.toString().split("|");
      this.currentLabelColors({ color: colors[1], backgroundColor: colors[0] });
    };

    //---------------------------------------------------------------------------------

    CRHierarchicalLabelModal.prototype.save = function(vm, e) {
      this.errors([]);

      var newText = cleanHtmlForLabels(this.currentLabelText()),
        self = this;

      if (this.currentLabel().id() > 0 && this.currentLabelParentValue() > 0) {
        if (this.currentLabelParentValue() == this.currentLabel().id()) {
          this.errors.push("Label cannot be parent of itself");
        } else if (isThisLabelDescendantOfThat.call(this, this.getLabelById(this.currentLabelParentValue()), this.currentLabel())) {
          this.errors.push("Invalid move");
        }
      }
      if (!this.currentLabelText()) this.errors.push("Name is required");
      if (this.currentLabelText() && !newText) this.errors.push("Invalid name");
      if (
        this.labels().some(function(l) {
          return newText.toLowerCase() == l.name().toLowerCase() && self.currentLabel().id() != l.id();
        })
      ) {
        this.errors.push("Label already exists");
      }

      if (this.errors().length) return;

      var savePacket = {
        labelId: this.currentLabel().id(),
        name: cleanHtmlForLabels(this.currentLabelText()),
        color: this.colorPicker.toString(),
        parentId: this.currentLabelParentValue() || 0,
        isOrgLabel: this.isPublic
      };

      this.messageBus.sendMessage("saveLabel", { label: this.currentLabel(), savePacket: savePacket, button: e.target });
    };

    CRHierarchicalLabelModal.prototype.getLabelById = function(id) {
      return Linq.From(this.labels()).Single(function(label) {
        return label.id() == id;
      });
    };

    CRHierarchicalLabelModal.prototype.deleteLabel = function(vm, evt) {
      this.errors([]);

      if (!this.deleteConfirm()) this.errors.push("Please click the confirm box");
      if (this.currentLabel().children().length) this.errors.push("This label has child labels and cannot be deleted");

      if (this.errors().length) return;

      this.messageBus.sendMessage("deleteLabel", { label: this.currentLabel(), button: evt.target });
    };

    return CRHierarchicalLabelModal;
  })();

  return {
    singleLabelsCollectionSelect2Plugin: singleLabelsCollectionSelect2Plugin,
    addGenericLabelsCollection: addGenericLabelsCollection,
    CRHierarchicalLabelModal: CRHierarchicalLabelModal
  };
});
